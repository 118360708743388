@charset "utf-8";

body {
	overflow-x: hidden;
	background: black;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	&.disable-scroll {
		overflow-y: hidden;
		height: 100% !important;
	}
}

body,
html,
#root {
	margin: 0;
	width: 100%;
	min-height: 100svh;
}

#root {
	flex-direction: column;
	justify-content: center;
	display: flex;
}

* {
	box-sizing: border-box;
}
